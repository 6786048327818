.loader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  line-height: 100vh;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 9999;
  opacity: 0.5;
}
.loader_loading {
  display: block;
}
.loader_loading + * {
  display: none;
}