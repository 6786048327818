.plans {
  height: 100%;
  background-color: #ffffff;
}

.price {
  display: inline-block;
  text-align: left;
  padding-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
}

.price_round {
  margin-bottom: auto;
  background: -webkit-linear-gradient(#005bff, #3ac267);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 2.5rem;
}

.price > * {
  display: block;
  font-size: 14px;
}

.feature {
  margin: 0 2rem;
}

.banner {
  width: 100%;
  padding-bottom: 1rem;
}

.loading {
  background-color: #ffffff;
  height: 100%;
}