.share_btn {
  margin-bottom: 4px !important; 
  border: none !important;
  border-radius: 50% !important;
  padding: 1rem !important;
}

.share_title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: auto;
  font-size: 14px;
}

.twitter_btn {
  background: linear-gradient(45deg, #1da1f2 0%, #69c6ff 100%);
}

.linkedin_btn {
  background: linear-gradient(45deg, #0e76a8 0%, #2398d1 100%);
}

.vk_btn {
  background: linear-gradient(45deg, #4c75a3 0%, #6e9dd1 100%);
}

.reddit_btn {
  background: linear-gradient(45deg, #ff5700 0%, #ff965f 100%);
}

.viber_btn {
  background: linear-gradient(45deg, #8f5db7 0%, #b883e3 100%);
}

.tg_btn {
  background: linear-gradient(45deg, #0088cc 0%, #51bef4 100%);
}

.fb_btn {
  background: linear-gradient(45deg, #3668c1 0%, #6d9df2 100%);
}

.whatsapp_btn {
  background: linear-gradient(45deg, #25d366 0%, #62f298 100%);
}

.system_share_btn {
  background: linear-gradient(45deg, #5993ff 0%, #97b5ff 100%);
}

.email_share_btn {
  background: linear-gradient(45deg, #ffa500 0%, #ffcf77 100%);
}