@media (min-width: 720px) {
  .frame_outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }

  .frame_inner {
    position: relative;
    margin: auto;
    width: 25rem;
    max-width: 100%;
    height: 45rem;
    max-height: 100%;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 8%);
    transform: translateZ(0);
  }
}