.wrapper {
  width: 100%;
  height: calc(100% - 64px);
  background-color: rgb(248, 248, 248);
}

.wrapper > * {
  height: 100%;
  top: auto;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 8%);
  @media (max-width: 992px) {
    height: calc(100% - 64px);
  }
}

.desktop > * {
  border-radius: 0;
  width: 100%;
  box-shadow: none;
}

.phone > * {
  width: 360px;
  max-height: 640px;
}

.tablet > * {
  width: 560px;
  max-height: 840px;
}
