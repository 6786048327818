.NavigationBar {
  height: 55px;
  z-index: 11;
  background-color: #fff;
}

@media (min-width: 992px) {
  .NavigationBar {
    display: block !important;
    height: 100%;
  }
  .menu_item_name {
    font-size: 18px !important;
  }
  .menu_item_icon {
    font-size: 24px !important;
  }
}

.menu_item_name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: auto;
  font-size: 14px;
}

.menu_item_icon {
  width: 100%;
  font-size: 18px;
}
