.popup_wrapper {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  top: 0;
  left: 0;
}

.popup_container {
  max-width: 500px !important;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  margin-right: -50%;
  padding-bottom: 15px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.popup_card {
  border: none !important;
  border-radius: .5rem !important;
}

.popup_close {
  position: absolute;
  right: 10px;
}

.popup_header_title {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}