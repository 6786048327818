.CopyButton_link {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #000
}

.CopyButton {
  margin-bottom: 15px;
  width: 100%;
}

.CopyButton:focus {
  box-shadow: none !important;
}

.copy_col {
  overflow: hidden;
}