.Share {
  display: flex;
  flex-direction: column;
  left: 0%;
  top: 0%;
  background-color: #fff;
}

.Share_top {
  background-image: url("../../assets/bg-share.jpg"); 
  background-position: center;
  background-size: cover; 
  background-repeat: no-repeat;
  padding: 3rem !important;
  margin: 20px 0;
  border-radius: 10px; 
}

.Share > div.body{
  height: 100%;
}

.Share_link_card {
  border: none !important;
}