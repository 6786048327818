.Form {
  padding: 0 !important;
  font-size: 18px !important;
  background-image: none !important;
  border: none !important;
  cursor: pointer;
}

.option_title {
  font-size: 18px;
  margin-bottom: 0;
}

.option_subtitle {
  color: gray;
  font-size: 14px;
  margin-bottom: 0;
}

.upgrade_btn {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  border-radius: 0.2rem !important;
}