.project {
  min-height: 6rem;
  cursor: pointer;
}

.site_row {
  width: 100%;
}

.info {
  width: 10% !important;
}

.info > * {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 95%;
}

.img {
  width: 48px;
  height: 48px;
}