.addTo_btn_action {
  color: #005bff
}

.addTo_btn {
  width: 100%;
  margin-bottom: 1rem !important;
}

.addTo_btn:focus {
  box-shadow: none !important;
}

.addTo_img {
  padding-bottom: 1rem;
}

.addTo_link {
  font-size: 18px;
}