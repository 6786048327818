.wrapper {
  position: relative;
  width: 100% !important;
  display: flex;
  justify-content: center;
  z-index: 999;
  background-color: #fff;
  height: 72px;
}

.buttons {
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 5px;
}

.buttons > * {
  padding: .6rem 1rem !important;
}