.notifications {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.5);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
}